const translations = {
    PL: {
        title: "Obliczenie ilości ciasta",
        productLabel: "Co pieczesz dzisiaj?",
        chooseOption: "Wybierz",
        bread: "Chleb",
        pizza: "Pizza",
        breadWeight: "Waga chleba (g):",
        tray:"Kształt blachy do pieczenia:",
        tray5cm:"Wybierz blachę o wysokości co najmniej 5 cm!",
        focaccia: "Focaccia",
        squareShape:"Prostokątny",
        roundShape:"Okrągły",
        calculate: "Oblicz",
        length:"Długość (cm):",
        width:"Szerokość (cm):",
        reset: "Reset",
        diameter:"Średnica (cm):",
        pcs:"Ile sztuk?",
        period:"Pora roku:",
        periodSuggest: "W ciepłe pory roku zużyjesz mniej zakwasu niż w zimne.",
        summer:"Wiosna-Lato",
        winter:"Jesień-Zima",
        water:"Hydratacja (%):",
        waterSuggest:"Jeśli nie masz dużego doświadczenia, zalecam pozostawienie domyślnego.",
        recipeDetails:"Szczegóły twojego przepisu",
        rec:"Przepis:",
        pieces:"Sztuki:",
        flour:"Mąka:",
        wat:"Woda:",
        salt:"Sól:",
        sourdough:"Zakwas:",
        oil:"Oliwa:",
        oil1:"łyżka",
        oil2:"łyżki",
        oil3:"łyżek",
        fatBoy:"Uwaga, wykryte obżartuch!",
        fatBoy1:"Rozumiem Twoją ochotę na ",
        fatBoy2:"specjalista dietetyk ",
        fatBoy3:"Dr. Drożdże ",
        fatBoy4:"zaraz cię przyjmie!"
    },
    EN: {
        title: "Dough Calculator",
        productLabel: "What are you gonna bake today?",
        chooseOption: "Choose",
        bread: "Bread",
        pizza: "Pizza",
        breadWeight: "Loaf Weight (g):",
        tray:"Baking tray shape:",
        focaccia: "Focaccia",
        squareShape:"Square-shaped",
        roundShape:"Round-shaped",
        tray5cm:"Choose a tray at least 5cm high!",
        calculate: "Calculate",
        length:"Length (cm):",
        width:"Width (cm):",
        reset: "Reset",
        diameter:"Diameter (cm):",
        pcs:"How many pieces?",
        period:"Season:",
        periodSuggest:"On summer time you want to use less sourdough than on winter time.",
        summer:"Spring-Summer",
        winter:"Autumn-Winter",
        water:"Hydratation (%):",
        waterSuggest:"If you don't have great experience, don't change the suggested value.",
        recipeDetails:"Your recipe details",
        rec:"Recipe:",
        pieces:"Pcs:",
        flour:"Flour:",
        wat:"Water:",
        salt:"Salt:",
        sourdough:"Sourdough:",
        oil:"Olive Oil:",
        oil1:"tbsp",
        oil2:"tbsp",
        oil3:"tbsp",
        fatBoy:"Ops, Crumb Snatcher detected!",
        fatBoy1:"I understand your craving for ",
        fatBoy2:"specialist dietitian ",
        fatBoy3:"Dr. Yeasty ",
        fatBoy4:"will immediately receive you!"
    },
    IT: {
        title: "Master Calcolatore",
        productLabel: "Cosa vuoi fare oggi?",
        chooseOption: "Scegli",
        bread: "Pane",
        pizza: "Pizza",
        tray:"Forma della teglia:",
        tray5cm:"Scegli una teglia alta almeno 5cm!",
        breadWeight: "Peso della pagnotta (g):",
        squareShape:"Rettangolare",
        roundShape:"Tonda",
        focaccia: "Focaccia",
        length:"Lunghezza (cm):",
        width:"larghezza (cm):",
        calculate: "Calcola",
        reset: "Reset",
        diameter:"Diametro (cm):",
        pcs:"Quanti pezzi?",
        period:"Stagione:",
        periodSuggest:"In estate utilizzi meno lievito madre rispetto all'inverno.",
        summer:"Primavera-Estate",
        winter:"Autunno-Inverno",
        water:"Idratazione (%):",
        waterSuggest:"Se non hai molta esperienza, lascia il valore consigliato.",
        recipeDetails:"Dettagli della ricetta",
        rec:"Ricetta:",
        pieces:"Pz:",
        flour:"Farina:",
        wat:"Acqua:",
        salt:"Sale:",
        sourdough:"L.Madre:",
        oil:"Olio:",
        oil1:"cucchiaio",
        oil2:"cucchiai",
        oil3:"cucchiai",
        fatBoy:"Attenzione, rilevato un Ghiottone!",
        fatBoy1:"Posso capire la tua brama di ",
        fatBoy2:"lo specialista dietologo ",
        fatBoy3:"Dr. Saccaro ",
        fatBoy4:"ti riceverà immediatamente!"
    }
};

export default translations;
